<template>
  <div
    v-loading.fullscreen.lock="loading"
    :element-loading-text="loadingText"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div>
      <!-- <SearchFilter @change="onFilterChange" /> -->
      <CCard>
        <CCardHeader>
          <CRow class="justify-content-between">
            <div class="mx-3">
              <strong style="line-height:38px;">{{$t('cloud.title.live_alarm')}}</strong>
            </div>
            <div class="mx-3">
              <span v-if="state==='live'">{{$t('cloud.title.live_updating')}}</span>
              <span v-if="state==='stop'">{{$t('cloud.title.pause_update')}}</span>
              <i v-if="state==='live'" class="el-icon-loading mx-2 text-success h4 d-inline-block" />
              <i v-else-if="state==='stop'" class="el-icon-video-pause mx-2 text-danger h4 d-inline-block" />
              <CButton :color="state==='stop'?'info':'light'" class="mx-1" @click="state='stop'">
                <CIcon name="cil-media-stop" /> {{$t('button.stop')}}
              </CButton>
              <CButton :color="state==='live'?'info':'light'" @click="restartUpdate">
                <CIcon name="cil-media-play" /> {{$t('button.start')}}
              </CButton>
            </div>
          </CRow>
        </CCardHeader>
      </CCard>
      <CCard v-for="item in list" :key="`live-alarm-${item.id}`">
        <CCardBody>
          <AlarmDetail :alarm="item" :observer="intersectionObserver" />
        </CCardBody>
      </CCard>
      <TopBtn />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import qs from 'qs'
import TopBtn from '@/components/TopBtn.vue'
import AlarmDetail from './AlarmDetail.vue'
import { format, addDays } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';

export default {
  name: 'LiveAlarm',

  components: {
    AlarmDetail,
    TopBtn
  },
  computed: {
    ...mapState([
      'capability'
    ]),
    loadingText() {
      return `Loading...`;
    }
  },
  data () {
    return {
      loading: true,
      forceExpand: true,
      state: 'live',

      pageTotal: 1,
      pageCurrent: 1,
      pageDirectSet: 1,
      pageIpp: 20,
      pageOrder: 'desc',
      filters: {},
      count: 0,
      list: [],

      FIELDS: [
        {key: 'color_label', label: ''},
        {key: 'fmt_time', _style: 'width:180px;'},
        {key: 'event_type_name', label: this.$t('cloud.title.event_type')},
        {key: 'event_name', label: this.$t('cloud.title.event_name')},
        {key: 'branch_name', label: this.$t('cloud.title.branch')},
        {key: 'device_name', label: this.$t('cloud.title.device')},
        {key: 'event_meta', label: this.$t('cloud.title.event_meta')}
      ],

      intersectionObserver: null,
      FILTER_TARGET_MODEL: 'alarm_view',
    }
  },
  async mounted() {
    this.intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.vueInstance.getSnapshots();
          this.intersectionObserver.unobserve(entry.target);
        }
      })
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    });
    document.addEventListener('alarm_notification', this.cbAlarmNotification);
    // console.log('alarm_notification 이벤트 리스너 등록')
    await this.getList();
  },
  beforeDestroy() {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
    document.removeEventListener('alarm_notification', this.cbAlarmNotification);
    // console.log('alarm_notification 이벤트 리스너 제거')
  },
  methods: {
    async restartUpdate() {
      if (this.state === 'live') return;
      await this.getList();
      this.state = 'live';
    },
    cbAlarmNotification(e) {
      // 라이브 업데이트중이 아니면 무시
      if (this.state !== 'live') return;

      // 이미 있으면 무시
      const el = e.detail.message;
      const is = this.list.some(item => item.id === el.id);
      if (is) return;

      // 최신 알람보다 오래된 알람이면 무시
      const recent = this.list.at(0);
      if (new Date(recent.ts).getTime() > new Date(el.ts).getTime()) return;

      const utcTime = el.ts;
      const timeZone = this.capability.user_profile.timezone;
      const alarm_date = utcToZonedTime(utcTime, timeZone);
      el.device_name = `${el.bridge_name}${el.ch?' [CH '+el.ch+']':''}`;
      el.fmt_time = this.$utility.GetDateTimeStr('$yyyy-$mm-$dd $HH:$MM:$ss', alarm_date);
      el.event_type_name = this.$t(`cloud.title.eventtype_${el.event_type}`);
      el.detailsShow = false;
      this.list.unshift(el);
      if (this.list.length > 30) {
        this.list.length = 30;
      }
    },
    async getList(cb) {
      let query_string = '';
      let params = this.buildParam(this.pageIpp, this.pageCurrent, this.pageOrder);

      if (params) {
        query_string = '?' + qs.stringify(params);
      }

      this.list = [];
      this.loading = true;
      await axios.get(`/api/alarm/${query_string}`)
        .then(result => {
          let list = this.$utility.copy(result.data.list);
          this.pageTotal = result.data.page.total;
          this.pageCurrent = result.data.page.current;
          this.count = result.data.page.count;
          this.list = list.map(el => {
            const utcTime = el.ts;
            const timeZone = this.capability.user_profile.timezone;
            const alarm_date = utcToZonedTime(utcTime, timeZone);
            el.device_name = `${el.bridge_name}${el.ch?' [CH '+el.ch+']':''}`;
            el.fmt_time = this.$utility.GetDateTimeStr('$yyyy-$mm-$dd $HH:$MM:$ss', alarm_date);
            el.event_type_name = this.$t(`cloud.title.eventtype_${el.event_type}`);
            el.detailsShow = false;
            return el;
          });
          if (cb) {
            cb();
          }
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        })
    },
    async onUpdatePage() {
      this.pageDirectSet = ""+this.pageCurrent;
      await this.getList();
    },
    async onChangePageDirectSet() {
      if (!this.pageDirectSet || Number(this.pageDirectSet) <= 0) this.pageDirectSet = "1";
      else if (+this.pageDirectSet >= this.pageTotal) this.pageDirectSet = ""+this.pageTotal;
      this.pageCurrent = +this.pageDirectSet;
      await this.getList();
    },
    buildParam(ipp, page, order) {
      let params = {
        ipp: ipp,
        page: page,
        order: 'desc'
      }
      if (order) params.order = order;
      if (this.filters.branch) params.branch = this.filters.branch;
      if (this.filters.event_types && this.filters.event_types.length) {
        params.event_types = this.filters.event_types.join(',');
      }
      if (this.filters.event_name) {
        params.event_name = this.filters.event_name;
      }
      if (this.filters.event_meta) {
        params.event_meta = this.filters.event_meta;
      }
      if (this.filters.device) params.device = this.filters.device;
      if (this.filters.channels && this.filters.channels.length) params.channels = this.filters.channels.join(',');

      if (this.filters.ts_mode) {
        const timeZone = this.capability.user_profile.timezone;
        if (this.filters.ts_mode === 'today') {
          params.sts = this.startOfZonedDay(new Date(), timeZone).getTime();
          // params.order = 'asc';
        } else if (this.filters.ts_mode === 'd1') {
          const today = this.startOfZonedDay(new Date(), timeZone);
          const yesterday = this.startOfZonedDay(addDays(today, -1), timeZone);
          params.sts = yesterday.getTime();
          // params.order = 'asc';
        } else if (this.filters.ts_mode === 'd2') {
          const today = this.startOfZonedDay(new Date(), timeZone);
          const yesterday = this.startOfZonedDay(addDays(today, -1), timeZone);
          params.sts = yesterday.getTime();
          // params.order = 'asc';
        } else if (this.filters.ts_mode === 'h24') {
          params.sts = Date.now() - (1000 * 60 * 60 * 24);
          // params.order = 'asc';
        }
      } else if (this.filters.sts && this.filters.ets) {
        params.sts = this.filters.sts;
        params.ets = this.filters.ets;
      }
      if (this.filters.color_labels && this.filters.color_labels.length) {
        params.color_labels = this.filters.color_labels.join(",")
      }
      return params;
    },
    async onFilterChange(payload) {
      this.filters = this.$utility.copy(payload);
      await this.getList();
    },
    onEventDetail(alarm) {
      for (const item of this.list) {
        if (item.id !== alarm.id)
          item.detailsShow = false;
      }
      alarm.detailsShow = !alarm.detailsShow;
    },

    onDownload(t) {
      let postfix = '';
      if (this.count > 50000) {
        postfix = '_limit';
      }
      this.$confirm(this.$t(`cloud.title.confirm_backup${postfix}`, {limit:50000}), this.$t('cloud.title.confirm_title'), {
        confirmButtonText:this.$t('button.ok'),
        cancelButtonText:this.$t('button.cancel'),
        type:'warning'
      })
      .then(() => {
        let query_string = '';
        let params = this.buildParam(this.pageIpp, this.pageCurrent, this.pageOrder);
        params.download = t;

        if (params) {
          query_string = '?' + qs.stringify(params);
        }
        this.loading = true;
        axios.get(`/api/alarm/${query_string}`)
          .then(result => {
            this.waitAsyncReq(result.data);
          })
          .catch(error => {
            console.error(error);
            this.loading = false;
          })
      })
    },
    startOfZonedDay(date, timeZone) {
      const zonedDate = utcToZonedTime(date, timeZone);
      const startString = format(zonedDate, 'yyyy-MM-dd') + ' 00:00:00';
      return zonedTimeToUtc(startString, timeZone);
    },
  }
}
</script>
